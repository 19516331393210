import { FC, memo, useState } from 'react';

import { formatAmount } from 'utils/amounts';

import {
  Col,
  ColMoreInfo,
  FirstCol,
  Header,
  HeaderCell,
  HeaderTitle,
  MinusIcon,
  PlusIcon,
  Row,
  Table,
  Title,
  VectorDownIcon,
  VectorUpIcon,
} from './styles';
import type { Props } from './types';

const CashTable: FC<Props> = ({
  data,
  handleSortByColumn,
  sortApplied,
  headerColumns,
}) => {
  const [selectedRow, setSelectedRow] = useState<string | undefined>(undefined);

  const groupedData = data.reduce(
    (acc: Record<string, typeof data[0][]>, item) => {
      const normalizedAccount = item.account.replace(' Loan', '');

      if (!acc[normalizedAccount]) {
        // eslint-disable-next-line no-param-reassign
        acc[normalizedAccount] = [];
      }
      acc[normalizedAccount]?.push(item);
      return acc;
    },
    {} as Record<string, typeof data[0][]>,
  );

  return (
    <Table>
      <Header>
        {headerColumns.map((col) => (
          <HeaderCell key={col.titleColumn}>
            <HeaderTitle
              onClick={() =>
                handleSortByColumn ? handleSortByColumn(col) : undefined
              }
            >
              <Title>{col.titleColumn}</Title>
              {sortApplied?.titleColumn === col.titleColumn &&
                (sortApplied?.orderOrientation === 'DESC' ? (
                  <VectorDownIcon />
                ) : (
                  <VectorUpIcon />
                ))}
            </HeaderTitle>
          </HeaderCell>
        ))}
      </Header>
      {Object.entries(groupedData).map(([account, items]) => (
        <div key={account}>
          <Row key={account}>
            <FirstCol>
              {items[0]?.entity} {account}
            </FirstCol>
            <Col>
              {formatAmount(
                items.reduce((total, item) => item.cashUSD + total, 0),
              )}
            </Col>
            <Col>
              {formatAmount(
                items.reduce((total, item) => item.cashOther + total, 0),
              )}
            </Col>
            <Col>
              {formatAmount(
                items.reduce((total, item) => item.cashEquivalent + total, 0),
              )}
            </Col>
            <Col>
              {formatAmount(
                items.reduce((total, item) => item.treasuries + total, 0),
              )}
            </Col>
            <Col>
              {formatAmount(
                items.reduce(
                  (total, item) => item.totalLiquidityAvailable + total,
                  0,
                ),
              )}
            </Col>
            {items.length > 1 && (
              <ColMoreInfo
                onClick={() =>
                  setSelectedRow(selectedRow !== account ? account : undefined)
                }
              >
                {selectedRow === account ? <MinusIcon /> : <PlusIcon />}
              </ColMoreInfo>
            )}
          </Row>
          {selectedRow === account &&
            items.map((row, index) => (
              <Row
                key={row.account}
                $withoutBorder={index !== items.length - 1}
              >
                <FirstCol>
                  {row.entity} {row.account}
                </FirstCol>
                <Col>{formatAmount(row.cashUSD)}</Col>
                <Col>{formatAmount(row.cashOther)}</Col>
                <Col>{formatAmount(row.cashEquivalent)}</Col>
                <Col>{formatAmount(row.treasuries)}</Col>
                <Col>{formatAmount(row.totalLiquidityAvailable)}</Col>
              </Row>
            ))}
        </div>
      ))}
    </Table>
  );
};

export default memo(CashTable);
