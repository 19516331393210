import { useLocation, useRouteMatch } from 'react-router-dom';

import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import up from 'utils/paths';
import searchWithoutSort from 'utils/searchWithoutSort';
import type { KeySortOfColumn } from 'utils/sort/types';

import { useCurrentGrouping, useSorting } from '../CategoryDetail/logic';
import {
  useAssets,
  useFilteringSubtypes,
} from '../CategoryDetailFixedIncome/logic';
import { useData } from './logic';

export default function useConnect() {
  const {
    params: { categorySlug },
    url,
  } = useRouteMatch<{ categorySlug: string }>();
  const { selectedPortfolio } = useSelectedClient();
  const { search } = useLocation();
  const parentUrl = `${up(url)}${searchWithoutSort(search)}`;

  const { mobileRows, pageTitle, notionalValues, subtypes } = useData({
    portfolio: selectedPortfolio?.id,
    url,
    search,
  });

  const grouping = useCurrentGrouping({ search });

  const { currentSorting, compare, onChangeSorting } = useSorting();

  const {
    currentFiltering,
    multipleOptions,
    filterOptions,
    onChangeFiltering,
  } = useFilteringSubtypes({
    categorySlug,
    portfolio: selectedPortfolio?.id,
  });

  const { assets, balance, totalPandL } = useAssets({
    categorySlug,
    compare,
    multipleSubtypesOptions: multipleOptions,
    grouping,
    portfolio: selectedPortfolio?.id,
    url,
  });

  const headerColumns: KeySortOfColumn[] = [
    {
      titleColumn: `${assets?.length} Assets`,
      keyOrderAsc: 'alphabeticalR',
      keyOrderDesc: 'alphabetical',
    },
    {
      titleColumn: 'Shares',
      keyOrderAsc: 'sharesASC',
      keyOrderDesc: 'sharesDESC',
    },
    {
      titleColumn: 'Market Price',
      keyOrderAsc: 'marketPriceASC',
      keyOrderDesc: 'marketPriceDESC',
    },
    {
      titleColumn: 'Market Value',
      keyOrderAsc: 'marketValueASC',
      keyOrderDesc: 'default',
    },
    {
      titleColumn: 'Total (%)',
      keyOrderAsc: 'totalPorcASC',
      keyOrderDesc: 'totalPorcDESC',
    },
    {
      titleColumn: 'Cost',
      keyOrderAsc: 'costASC',
      keyOrderDesc: 'costDESC',
    },
    {
      titleColumn: 'Dividends Received',
      keyOrderAsc: 'dividendsReceivedASC',
      keyOrderDesc: 'dividendsReceivedDESC',
    },
    {
      titleColumn: 'P&L ($)',
      keyOrderAsc: 'pandlASC',
      keyOrderDesc: 'pandlDESC',
    },
  ];

  return {
    assets,
    balance,
    totalPandL,
    mobileRows,
    parentUrl,
    url,
    search,
    pageTitle,
    notionalValues:
      categorySlug === 'equity-market' || categorySlug === 'equity-individual'
        ? notionalValues
        : undefined,
    subtypes,
    categorySlug,
    handle: {
      changeFiltering: onChangeFiltering,
      changeSorting: onChangeSorting,
    },
    currentSorting,
    currentFiltering,
    filterOptions,
    headerColumns,
  };
}
