import type { FC } from 'react';
import { memo } from 'react';

import {
  Container,
  MaintenanceImg,
  PrimaryText,
  SecondaryText,
  TertiaryText,
} from './styles';

const Maintenance: FC = () => (
  <Container>
    <MaintenanceImg />
    <SecondaryText>WE ARE COMING SOON</SecondaryText>
    <PrimaryText>The website under maintenance!</PrimaryText>
    <TertiaryText>
      We hope to have the website available as soon as possible. sorry for the
      inconvenience
    </TertiaryText>
  </Container>
);

export default memo(Maintenance);
