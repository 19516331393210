import { createContext, FC, useContext, useMemo, useState } from 'react';

import type { MaintenanceModeContextModel } from './types';

const Context = createContext<MaintenanceModeContextModel | undefined>(
  undefined,
);

const MaintenanceModeProvider: FC = ({ children }) => {
  const [isMaintenanceMode, setMaintenanceMode] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      isMaintenanceMode,
      setMaintenanceMode,
    }),
    [isMaintenanceMode],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useMaintenanceMode() {
  return useContext(Context);
}

export default MaintenanceModeProvider;
